<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Edición de la Definición de la Relacion {{ relacion.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container v-if="!loadingSave">

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="relacion.nombre"
                label="Nombre de la Relación (Descriptivo)"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="relacion.descripcion"
                label="Descripción (Info adicional)"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="relacion.layout_id1"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Layout 1"
                :rules="required"
              ></v-select>
            </v-col>
          
            <v-col cols="12" md="6">
              <div
                v-if="loadingLayouts"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingLayouts"
                v-model="relacion.layout_id2"
                :items="fuentes"
                :item-text="'nombre'"
                :item-value="'id'"
                attach
                label="Layout 2"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" md="4">
              <v-switch v-model="relacion.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <CButton
                v-if="!loadingSave"
                color="primary"
                class="px-4"
                @click="agregarTransformacion()"
                >+ Relación</CButton
              >
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="loadingTransformaciones">
                <div class="spinner-border" role="status"></div>
              </div>
              <v-data-table
                v-if="!loadingTransformaciones"
                :headers="headers"
                :items="transformacionesArray"
                :items-per-page="10"
                class="elevation-1 relations_field"
              >
                <template v-slot:item.numero="{ item }">
                  {{ transformacionesArray.indexOf(item) + 1 }}
                </template>


                <template v-slot:item.valorA="props">
                  <v-select
                    v-model="props.item.valorA"
                    :items="layoutA"
                    :item-text="'text'"
                    :item-value="'value'"
                    label="Valor A"
                    attach
                  ></v-select>
                </template>

                <template v-slot:item.valorB="props">
                  <v-select
                    v-model="props.item.valorB"
                    :items="layoutB"
                    :item-text="'text'"
                    :item-value="'value'"
                    label="Valor B"
                    attach
                  ></v-select>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>



        </v-container>
      </v-form>
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <!-- <pre>{{consulta}}</pre> -->

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RelacionService from "@/services/relacion.service";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import VariableService from "@/services/variable.service";
import EventBus from "@/common/EventBus";

import { mapState } from "vuex";

export default {
  data() {
    return {
      relacion: {
        nombre: undefined,
        descripcion: undefined,
        layout_id1: undefined,
        layout_id2: undefined,
        transformaciones: [],
        activo: 1,
      },
      transformacionesArray: [],
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      fuentes: [],
      campos: [],
      layoutA: [],
      layoutB: [],
      transformaciones: [],
      headers: [
        { text: "Numero", value: "numero", sortable: false },
        {
          text: "Campo Fuente 1",
          value: "valorA",
          sortable: false,
        },
        {
          text: "Campo Fuente 2",
          value: "valorB",
          sortable: false,
        },

        { text: "Borrar", value: "actions", sortable: false },
      ],
      loadingLayouts: false,
      loadingCategorias: false,
      loadingLabelsPanoramicos: false,
      loadingTransformaciones: false,
      loadingCampos: false,
      loadingRoles: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    "consulta.layout_id"(newVal) {
      this.fetchCampos("");
    },
  },
  mounted() {
    if (!this.permissions.includes("Edicion Consolidacion Archivos"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_relacion")) {
      this.relacion = JSON.parse(localStorage.getItem("datos_relacion"));
      console.log("relacion existente")
      console.log(this.relacion)
      this.transformacionesArray = JSON.parse(this.relacion.transformaciones);
      console.log(this.transformacionesArray)

      this.fetchVariables(this.relacion.layout_id1,1);
      this.fetchVariables(this.relacion.layout_id2,2);
    }

    this.fetchLayouts("")
    //EventBus.on("fetchVariablesEvent1", (cadenaBusqueda) => {
      //this.fetchVariables(cadenaBusqueda,1);
    //});
  },
  methods: {
    save() {
      this.loadingCampos = false
      //inicio limpio relaciones vacias
      this.transformacionesArray.forEach(function(item, index, object){
        console.log(item);
        if(item.transformacion == ''){
          object.splice(index);
        }
      });
      this.relacion.transformaciones = JSON.stringify(
        this.transformacionesArray
      );
      this.loadingSave = true;
      console.log("guardando Relacion ------------------------------")
      console.log(this.relacion);
      if (this.relacion.hasOwnProperty("id")) {
        RelacionService.editItem(this.relacion.layout_id1, this.relacion).then(
          (response) => {
            console.log(response)
            this.$router.push({ path: `/cargue-archivos/relaciones` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        RelacionService.newItem(this.relacion.layout_id1, this.relacion).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/relaciones` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }


    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if (cadenaBusqueda == "") cadenaBusqueda = "?activo=1";
      else cadenaBusqueda = cadenaBusqueda + "&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchVariables(cadenaBusqueda,offset) {
      this.loadingCampos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda="?layout_id="+cadenaBusqueda+"&activo=1";
      console.log(cadenaBusqueda)
      VariableService.getItems(cadenaBusqueda).then(
        (response) => {
          //this.items = response.data;
          let itemsarray
          

          itemsarray = response.data.map(function f(x) {
            return { value:x.id, text: x.nombre };
          });
          /*console.log(response.data);
          console.log(this.layoutA);
          console.log(this.layoutB);*/
          
          if(offset==1)
            this.layoutA = itemsarray
          else
            this.layoutB = itemsarray
          
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    agregarTransformacion() {
      this.fetchVariables(this.relacion.layout_id1,1);
      this.fetchVariables(this.relacion.layout_id2,2);
      this.transformacionesArray.push({
        valorA: "",
        valorB: "",
      });
    },
    deleteItem(transformacion) {
      let i = this.transformacionesArray.indexOf(transformacion);
      this.transformacionesArray.splice(i, 1);
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/relaciones" });
    },
  },
};
</script>

<style>
</style>
